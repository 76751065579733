import '../cssPages/priceListPage.css';

const PriceListPage = () => {
    return (
        <div className='main-page-price-list' style={{backgroundImage: `url("/images/building3.jpg")`, backgroundRepeat: `no-repeat`, backgroundSize: `contain`, backgroundPosition: `center`}}>
            <div className='price-list-container'>
                <h1>Прайс-лист</h1>
                <div className='price-list-content'>
                    <h1>
                        <p>Для оптовых покупателей, наша компания предлагает гибкую <b>систему скидок</b>, которая зависит от величины заказа и продолжительности сотрудничества с нами.</p>
                        <p>Для получения оптового прайс-листа Вам необходимо отправить запрос, с указанием полного названия Вашей компании, на E-mail: <a href='mailto:vladimir@bemis.su'>vladimir@bemis.su</a></p>
                    </h1>
                </div>
            </div>
        </div>
    )
}

export {PriceListPage}
import { useParams, useNavigate, Link } from "react-router-dom";

import { useEffect } from "react";

import { GoArrowLeft } from "react-icons/go";

import Reels11 from '../json/reels11.json';
import Reels10 from '../json/reels10.json';
import Shields10 from '../json/shields10.json';
import ComponentsForReels from '../json/componentsForReels.json';

import { useState } from "react";

import '../cssPages/reelsAndShieldsPage.css';

const IfType = ({props, type}) => {
    if (props) {
        return (
            <li className="reels-teble">
                <div className="reels-table-text">{type}</div>
                <div className="reels-table-data">{props}</div>
            </li>
        )
    }
}

const IfOverall = ({props, type}) => {
    if (props) {
        return (
            <li className="modal-table-image">
                <div className="reels-table-text">{type}</div>
                <a href={props}><img src={props} alt="dataOverall"/></a>
            </li>
        )
    }
}

const IfImg = ({propsImg}) => {
    if (propsImg) {    
    return (
        <a href={propsImg}><img src={propsImg} alt="dataOverall"/></a>
        )
    }
}

const IfExamples = ({props, type, dataFunc}) => {
    if (props) {
        return (
            <li className="modal-table-image">
                <div className="reels-table-text">{type}</div>
                <IfImg propsImg={dataFunc.executionExamples1}/>
                <IfImg propsImg={dataFunc.executionExamples2}/>
                <IfImg propsImg={dataFunc.executionExamples3}/>
            </li>
        )
    }
}


const IfReelsComplectation = ({props, link}) => {
    if(props && link) {
        return (<Link to={link} className="posibility-conf-links"><div className="reels-table-data-container-title">{props}</div></Link>)
    } else if (props) {
        return (<div className="reels-table-data-container-title">{props}</div>)
    }
}

const IfReelsOneTwo = ({props, type}) => {
    if(props) {
        return (<div className="reels-table-text">{type}</div>)
    }
}

const IfReelsThree = ({props, props1, props2}) => {
    if (props && props1 && props2) {
        return (
            <div className="reels-table-title-container">
                <div className="reels-table-data-container-title">{props}</div>
                <div className="reels-table-data-container-title">{props1}</div>
                <div className="reels-table-data-container-title">{props2}</div>
            </div>
        )
    }
}

const IfReelsOneThree = ({props , dataFunc}) => {
    if (props) {
        return (
            <li className="reels-teble">
                <IfReelsOneTwo props={dataFunc.possibilityOfConfiguration1} type={"Возможность комплектации:"} />
                <div className="reels-table-container-conf">
                <IfReelsComplectation props={dataFunc.possibilityOfConfiguration1} link={dataFunc.possibilityOfConfigurationLinks1} />
                <IfReelsComplectation props={dataFunc.possibilityOfConfiguration2} link={dataFunc.possibilityOfConfigurationLinks2} />
                <IfReelsComplectation props={dataFunc.possibilityOfConfiguration3} link={dataFunc.possibilityOfConfigurationLinks3} />
                <IfReelsComplectation props={dataFunc.possibilityOfConfiguration4} link={dataFunc.possibilityOfConfigurationLinks4} />
                <IfReelsComplectation props={dataFunc.possibilityOfConfiguration5} link={dataFunc.possibilityOfConfigurationLinks5} />
                <IfReelsComplectation props={dataFunc.possibilityOfConfiguration6} link={dataFunc.possibilityOfConfigurationLinks6} />
                </div>
            </li>
        )
    }
}

const IfReelsThreeOne = ({props , dataFunc}) => {
    if (props) {
        return (
            <li className="reels-teble">
                <IfReelsOneTwo props={dataFunc.possibilityOfConfiguration1} type={"Вместимость кабеля:"} />
                <div className="reels-table-container">
                    <IfReelsThree props={dataFunc.cableCapacity11} props1={dataFunc.cableCapacity12} props2={dataFunc.cableCapacity13} />
                    <IfReelsThree props={dataFunc.cableCapacity21} props1={dataFunc.cableCapacity22} props2={dataFunc.cableCapacity23} />
                    <IfReelsThree props={dataFunc.cableCapacity31} props1={dataFunc.cableCapacity32} props2={dataFunc.cableCapacity33} />
                    <IfReelsThree props={dataFunc.cableCapacity41} props1={dataFunc.cableCapacity42} props2={dataFunc.cableCapacity43} />
                    <IfReelsThree props={dataFunc.cableCapacity51} props1={dataFunc.cableCapacity52} props2={dataFunc.cableCapacity53} />
                </div>
            </li>
        )
    }
}


const ReelsAndShieldsPage = () => {

    const {id, reelsAndShieldsParams} = useParams();
    const arrayOfReels = [Reels11, Reels10, Shields10, ComponentsForReels]
    
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [selectedArray, setSelectedArray] = useState(null);



    useEffect(() => {
        const setArrayId = () => {
            if (parseInt(id) === 12) {
                setSelectedArray(arrayOfReels[0]);
            } else if (parseInt(id) === 13) {
                setSelectedArray(arrayOfReels[1]);
            } else if (parseInt(id) === 14) {
                setSelectedArray(arrayOfReels[3]);
            } else if (parseInt(id) === 15) {
                setSelectedArray(arrayOfReels[2]);
            }
        };
        setArrayId();
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <button className="button-back" onClick={goBack}><GoArrowLeft /></button>
            {selectedArray && selectedArray.map(data => {if (parseInt(reelsAndShieldsParams) === data.id) {return (
                    <div className="reels-shields-main-container">
                        <h1>{data.name}</h1>
                        <div className="modal-fullname-img">
                            <a href={data.image}><img src={data.image} alt="dataImg"/></a>
                            <div className="modal-fullname-img-text">{data.fullName}</div>
                        </div>
                        <ul className="modal-table-ul">
                            <IfType props={data.model} type={"Модель:"} />
                            <IfType props={data.description} type={"Описание:"} />
                            <IfType props={data.frameMaterial} type={"Материал рамы:"} />
                            <IfType props={data.drumMaterial} type={"Материал барабана:"} />
                            <IfType props={data.drumDiameter} type={"Диаметр барабана:"} />
                            <IfType props={data.caseMaterial} type={"Материал корпуса:"} />
                            <IfType props={data.material} type={"Материал:"} />
                            <IfType props={data.set} type={"Комплект:"} />
                            <IfType props={data.possibilityOfInstallingCircuitBreakers} type={"Возможность установки автоматических выключателей:"} />
                            <IfType props={data.possibilityOfInstallingASeal} type={"Возможность установки пломбы:"} />
                            <IfReelsOneThree dataFunc={data} props={data.possibilityOfConfiguration1}/>
                            <IfReelsThreeOne props={data.cableCapacity11} dataFunc={data} />
                            <IfType props={data.resource} type={"Ресурс:"} />
                            <IfType props={data.degreeOfProtection} type={"Степень защиты:"} />
                            <IfType props={data.netWeight} type={"Вес нетто:"} />
                            <IfType props={data.netWeightWithoutCable} type={"Вес без кабеля:"} />
                            <IfOverall props={data.overallAndInstallationDimensions} type={"Габаритно-установочные размеры:"} />
                            <IfExamples dataFunc={data} props={data.executionExamples1} type={"Примеры исполнения:"}/>
                        </ul>
                    </div>
                )} else {
                    return (null);
                }})}
        </div>
    )
}

export {ReelsAndShieldsPage};
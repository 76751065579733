import { Link, useParams, useNavigate } from "react-router-dom";

import catalogList from "../json/catalogList.json";
import { GoArrowLeft } from "react-icons/go";

import Optimum from '../json/optimum.json';
import Prom1 from '../json/1prom13.json';
import Prom2 from '../json/2prom18.json';
import Prom3 from '../json/3prom8.json';
import Prom4 from '../json/4prom33.json';
import Prom5 from '../json/5prom49.json';
import Prom6 from '../json/6prom60.json';
import Svar from '../json/svar6.json';
import Mnog from '../json/mnog21.json';
import NewProducts from '../json/newProducts4.json';
import Flashlights from '../json/flashlights.json';
import Reels11 from '../json/reels11.json';
import Reels10 from '../json/reels10.json';
import Shields10 from '../json/shields10.json';
import ComponentsForReels from '../json/componentsForReels.json';
import Professional from '../json/professional.json';
import Economic from '../json/economic.json';

import '../cssPages/catalogTitlePage.css';
import Modal from '../Modal/Modal';

import { useState } from "react";


const arrayOfForksAndSockets = [ 
    NewProducts, Optimum, Prom4, Prom2, Prom1, Prom5, Prom6, Prom3, Svar, Mnog, Professional, Economic, Reels11, Reels10, ComponentsForReels, Shields10, Flashlights
];

const IfType = ({props, type}) => {
    if (props) {
        return (
            <li className="modal-table">
                <div className="modal-table-text">{type}</div>
                <div className="modal-table-data">{props}</div>
            </li>
        )
    }
}

const IfOverall = ({props, type}) => {
    if (props) {
        return (
            <li className="modal-table-image">
                <div className="modal-table-text">{type}</div>
                <a href={props}><img src={props} alt="dataOverall"/></a>
            </li>
        )
    }
}


const CatalogTitlePage = () => {

    const {category, id} = useParams();
    const [selectedArray] = useState(arrayOfForksAndSockets[id]);
    const [currentValueOfTitle, setCurrentValueOfTitle] = useState(0);
    const [modalActiveCatalogTitle, setModalActiveCatalogTitle] = useState(false);

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <div>
            <button className="button-back-catalog-title" onClick={goBack}><GoArrowLeft /></button>
            <div className="catalog-title-title">
                <h1>{catalogList[id].name}</h1>
            </div>
            <div className="catalog-title-content">
                
                {
                    selectedArray && selectedArray.map (function (data) {
                        if (data.category === category && category !== "reelsAndShields") {
                        return (
                            <button  onClick={() => {setCurrentValueOfTitle(data.id); setModalActiveCatalogTitle(true)}} className="catalog-title-map-content">
                                <div className="catalog-title-map-content-text">
                                    <h2>{ data.name }</h2>
                                </div>
                                <div className="catalog-title-map-content-img">
                                    <img src={ data.image } alt="imageOfProduct" />
                                </div>
                                <div className="catalog-title-map-content-button">
                                </div>
                            </button>
                        );
                        } else if (category === "reelsAndShields") {
                            return (
                                <Link className="catalog-title-map-content" to={`/catalog/${category}/${id}/${data.id}`}>
                                    <div className="catalog-title-map-content-text">
                                        <h2>{ data.name }</h2>
                                    </div>
                                    <div className="catalog-title-map-content-img">
                                        <img src={ data.image } alt="imageOfProduct" />
                                    </div>
                                </Link>
                            );
                        } else {
                            return (null);
                        }
                    })
                }
                
            
            </div>
            <Modal active={modalActiveCatalogTitle} setActive={setModalActiveCatalogTitle}>
                {selectedArray && selectedArray.map(data => {if (currentValueOfTitle === data.id) {return (
                    <div className="modal-catalog-title">
                            <h1>{data.name}</h1>
                            <div className="modal-fullname-img">
                                <a href={data.image}><img src={data.image} alt="dataImg"/></a>
                                <div className="modal-fullname-img-text">{data.fullName}</div>
                            </div>
                            <ul className="modal-table-ul">
                                <IfType props={data.model} type={"Модель:"} />
                                <IfType props={data.color} type={"Цвет:"} />
                                <IfType props={data.description} type={"Описание:"} />
                                <IfType props={data.numberOfContacts} type={"Количество контактов:"} />
                                <IfType props={data.internationalClassification} type={"Международная классификация:"} />
                                <IfType props={data.voltage} type={"Напряжение:"} />
                                <IfType props={data.ratedCurrent} type={"Номинальный ток:"} />
                                <IfType props={data.degreeOfProtection} type={"Степень защиты:"} />
                                <IfType props={data.housingMaterial} type={"Материал корпуса:"} />
                                <IfType props={data.contactGroupMaterial} type={"Материал контактной группы:"} />
                                <IfType props={data.temperatureRange} type={"Температурный диапазон:"} />
                                <IfType props={data.standard} type={"Стандарт:"} />
                                <IfType props={data.netWeight} type={"Вес нетто:"} />
                                <IfOverall props={data.overallAndInstallationDimensions} type={"Габаритно-установочные размеры:"} />
                            </ul>
                    </div>
                )} else {
                    return (null);
                }})}
            </Modal>
        </div>
    )
}

export { CatalogTitlePage };
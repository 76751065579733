import React, { useEffect } from "react";

import { Link } from 'react-router-dom';

import Flickity from 'react-flickity-component';

import AOS from "aos";
import "aos/dist/aos.css";

import '../cssLibrary/flickity.css';
import '../cssPages/mainPage.css';

import bc135 from '../img/BC1-35.png';
import bg41016 from '../img/BG4-1016.png';
import bx10444 from '../img/BX1-0444.png';
import by51402 from '../img/BY5-1402-7410.png';

const newProducts = {
    id: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    title: ['Вилка кабельная', 'Колодка, 3 гнезда, с крышками', 'Розетка кабельная', 'Мультибокс', 'Вилка кабельная, угловая', 'Розетка на механизм, с крышкой', 'Вилка кабельная, угловая', 'Вилка кабельная, поворотная 60 градусов', 'Розетка на поверхность, 1-но гнездо', 'Колодка 2 гнезда', 'Колодка 3 гнезда', 'Колодка 4 гнезда'],
    image: ["/images/forSlider/1.png", bg41016, "/images/forSlider/2.png", bx10444, "/images/forSlider/8.png", by51402, "/images/forSlider/7.png", bc135, "/images/forSlider/3.png", "/images/forSlider/4.png", "/images/forSlider/5.png", "/images/forSlider/6.png"],
    link: [`/catalog/forks/1`, `/catalog/newsAndFlashlights/0`, `/catalog/sockets/1`, `/catalog/newsAndFlashlights/0`, `/catalog/forks/1`, `/catalog/newsAndFlashlights/0`, `/catalog/forks/1`, `/catalog/newsAndFlashlights/0`, `/catalog/sockets/1`, `/catalog/sockets/1`, `/catalog/sockets/1`, `/catalog/sockets/1`]
}

  const flickityOptions = {
    initialIndex: 3,
    autoPlay: true
}

const MainPage = () => {
    useEffect(() => {
        AOS.init({
          duration: 500,
          easing: "ease-out-cubic",
          disable: "phone"
        });
      }, []);
    return (
        <div className = "main-page" style={{backgroundImage: `url("/images/building3.jpg")`, backgroundRepeat: `no-repeat`, backgroundSize: `contain`}}>
        
        <Flickity
          className="Slider"
          elementType="div"
          disableImagesLoaded={false}
          options={flickityOptions}
          reloadOnUpdate
          static
        >
          {newProducts['id'].map((index) =>{
            return (
              <Link className="flickity-links" to={newProducts['link'][index - 1]}>
              <div key={index} className='Plate'>
                <h2>{newProducts['title'][index - 1]}</h2>
                <div className="slider-div" style={{
                  backgroundImage: `url(${newProducts['image'][index - 1]})`
                }}>
                </div>
              </div>
              </Link>
            )
          })}
        </Flickity>
        <div className="about-why-container">
          <div className="about-us">
            <h1 className="headers">BEMIS: сильный бренд с прогрессивным имиджем</h1>
            <div className="main-text">
              <h2>На мировом рынке электротехники есть не так много брендов, которые в сознании профессионалов и потребителей ассоциируются с высоким качеством, надежностью и инновационными достижениями. В их число давно и прочно входит турецкая марка Bemis.</h2>
              <h2>Она принадлежит крупнейшему в Турции производителю <a href="/PFD/Компания Bemis Elektrik.pdf" className="main-page-links">промышленного электрооборудования – компании Bemis Elektrik</a>. Это предприятие со штаб-квартирой в городе Бурса было основано еще в 1994 году. Начав с выпуска всего трех видов изделий, сегодня Bemis выпускает широчайший <Link to="/catalog" className="main-page-links">ассортимент электрооборудования</Link> в среднем ценовом сегменте. За много лет его продукция заслужила доверие специалистов в самых разных сферах: автомобиле- и машиностроении, строительстве, медицине, электроэнергетике, промышленной автоматике, судостроительстве, связи, пищевой отрасли, авиации.</h2>
              <h2>На российском рынке этот бренд представляет компания «Электроустановка Плюс» с центральным офисом в подмосковных Мытищах. Она является официальным представителем Bemis на территории РФ и имеет эксклюзивные права на дистрибуцию его продуктов.</h2>
            </div>
          </div>
          <div className="why-us">
            <div className="why-us-main-text">
              <h1 className="headers">Почему BEMIS – аргументы в пользу выбора</h1>
              <div className="main-text">
                <h2>BEMIS в течение последних десятилетий прочно занимает одну из лидирующих позиций в своей отрасли. География его продаж обширна. Кроме России компания имеет партнеров и филиалы по сбыту в Болгарии, Греции, Румынии, Египте, Великобритании, Иране, Ираке, Тунисе, Ливии, Южной Корее, ОАЭ и многих других странах.</h2>
                <h2>Секрет успеха марки складывается из многих равновесомых факторов: стабильно высокое качество, разумная ценовая политика, продуманная дистрибуция и хорошая скорость поставок и, послепродажное обслуживание и безупречный деловой имидж.</h2>
                <h2>Главный упор Bemis Elektrik делает на абсолютное качество. Все ее изделия создаются в соответствии с <a href="/PFD/Стандарты.pdf" className="main-page-links">высочайшими стандартами</a>, а процесс изготовления контролируется в собственных лабораториях на всех этапах – от поставки сырья до выхода конечного продукта. Bemis не использует в своих изделиях и их компонентах опасных для экологии веществ и ограничивает применение вредных соединений.</h2>
                <h2>Номенклатурный перечень изделий BEMIS чрезвычайно широк: силовые разъемы для низковольтной аппаратуры, многоконтактные промышленные сигнальные разъемы, самосматывающиеся катушки для передачи энергии, жидкостей, масел и сжатых газов, кабельные барабаны, силовые вилки, настенные и кабельные розетки, кнопки, распределительные коробки и щиты, электрические кабельные удлинители – катушки для провода, переносные светильники, фонари и др.</h2>
              </div>
              <div className="questions">
                <h2>Если у вас возникли вопросы, <Link to="/contacts" className="main-page-links">свяжитесь с нами</Link> и мы поможем найти решение.</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export {MainPage}
 import logo from "../img/logoB.png";

import catalogList from "../json/catalogList.json";

import '../cssPages/catalogPage.css';
import Modal from '../Modal/Modal';

import { useState } from "react";
import { Link } from "react-router-dom";


const CatalogPage = () => {

    const [modalActiveCatalog, setModalActiveCatalog] = useState(false);

    const [selectedPage, setSelectedPage] = useState(0);

    const catalogListMap = catalogList && catalogList.map(function(item) {
            if (item.id === 0) {
                return (
                    <div className="catalog-container">
                        <Link to={`/catalog/newsAndFlashlights/${item.id}`}>
                            <button className="catalog-name-button">
                                    <div className="name-count">
                                        <p className="item-name">{item.name}</p>
                                        <p className="item-count">{item.count}</p>
                                    </div>
                                    <div className="logo-description-container">
                                        <div className="logo-description">
                                            <p>{item.description}</p>
                                            <img src={logo} alt="Логотип" />
                                        </div>
                                    </div>
                            </button>
                        </Link>
                    </div>
                )
            } else if (item.id <= 11 && item.id > 0) {
            return (
                <div className="catalog-container">
                    <button className="catalog-name-button" onClick={function() {
                            setModalActiveCatalog(true);
                            setSelectedPage(item.id);
                        }}>
                            <div className="name-count">
                                <p className="item-name">{item.name}</p>
                                <p className="item-count">{item.count}</p>
                            </div>
                            <div className="logo-description-container">
                                <div className="logo-description">
                                    <p>{item.description}</p>
                                    <img src={logo} alt="Логотип" />
                                </div>
                            </div>
                    </button>
                </div>
        )
        } else if (item.id === 12) {
            return (<>
                <h1 className="reels-and-shields-h1">Катушки и комплектующие</h1>
                <div className="catalog-container">
                    <Link to={`/catalog/reelsAndShields/${item.id}`}>
                        <button className="catalog-name-button">
                                <div className="name-count">
                                    <p className="item-name">{item.name}</p>
                                    <p className="item-count">{item.count}</p>
                                </div>
                                <div className="logo-description-container">
                                    <div className="logo-description">
                                        <p>{item.description}</p>
                                        <img src={logo} alt="Логотип" />
                                    </div>
                                </div>
                        </button>
                    </Link>
                </div>
                </>
            );
        }
        else if (item.id > 12 && item.id < 15) {
            return (
                <div className="catalog-container">
                    <Link to={`/catalog/reelsAndShields/${item.id}`}>
                        <button className="catalog-name-button">
                                <div className="name-count">
                                    <p className="item-name">{item.name}</p>
                                    <p className="item-count">{item.count}</p>
                                </div>
                                <div className="logo-description-container">
                                    <div className="logo-description">
                                        <p>{item.description}</p>
                                        <img src={logo} alt="Логотип" />
                                    </div>
                                </div>
                        </button>
                    </Link>
                </div>
            )
        } else if (item.id === 15) {
            return (<>
                <h1 className="reels-and-shields-h1">Щиты и распределительные коробки</h1>
                <div className="catalog-container">
                    <Link to={`/catalog/reelsAndShields/${item.id}`}>
                        <button className="catalog-name-button">
                                <div className="name-count">
                                    <p className="item-name">{item.name}</p>
                                    <p className="item-count">{item.count}</p>
                                </div>
                                <div className="logo-description-container">
                                    <div className="logo-description">
                                        <p>{item.description}</p>
                                        <img src={logo} alt="Логотип" />
                                    </div>
                                </div>
                        </button>
                    </Link>
                </div>
                </>
            );
        } else if (item.id === 16) {
            return (
                <div className="catalog-container">
                    <Link to={`/catalog/newsAndFlashlights/${item.id}`}>
                        <button className="catalog-name-button">
                                <div className="name-count">
                                    <p className="item-name">{item.name}</p>
                                    <p className="item-count">{item.count}</p>
                                </div>
                                <div className="logo-description-container">
                                    <div className="logo-description">
                                        <p>{item.description}</p>
                                        <img src={logo} alt="Логотип" />
                                    </div>
                                </div>
                        </button>
                    </Link>
                </div>
            )
        }
        else {
            return (null)
        }
    });

    return (
        <div className="catalog-main-page" style={{backgroundImage: `url("/images/building3.jpg")`, backgroundRepeat: `no-repeat`, backgroundSize: `contain`}}>
            <h1 className="catalog-h1">Каталог</h1>
            <h1 className="reels-and-shields-h1">Разъемы</h1>
            <div className="catalog-main-content">{catalogListMap}</div>
            <Modal active={modalActiveCatalog} setActive={setModalActiveCatalog}>
                <div className="selected-catalog">
                    <div className="selected-catalog-name"><h1>{catalogList[selectedPage].name}</h1></div>
                    <div className="selected-catalog-buttons-container">
                        <Link to={`/catalog/sockets/${selectedPage}`}><button className="selected-catalog-button">Розетки</button></Link>
                        <Link to={`/catalog/forks/${selectedPage}`}><button className="selected-catalog-button">Вилки</button></Link>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export {CatalogPage}; 